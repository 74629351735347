<template>
    <div class="container" :style="{ height: `${conheight}px`}">
		<div class="content">
			<el-breadcrumb separator=">">
			  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			  <el-breadcrumb-item :to="{ path: '/info/induKnowledge' }">行业资讯</el-breadcrumb-item>
			  <el-breadcrumb-item>文章详情</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="article">
				<div class="art_left">
					<div class="art_title">
						{{artDetail.title}}
					</div>
					<div class="art_tips">
						<div>
							<span class="el-icon-time"></span>
							<span class="txt2">{{artDetail.createTime}}</span>
						</div>
						<div>
						    <span class="el-icon-star-off" @click="toCollect" v-if="Number(artDetail.isOwnFollow === 0)" />
							<span class="el-icon-star-on" style="font-size: 17px;margin-bottom: 1px;" @click="toNotCollect" v-else />


							<span class="txt2">{{artDetail.followCount?artDetail.followCount:0}}&nbsp;收藏</span>
							<span class="el-icon-view"></span>
							<span class="txt2">{{artDetail.pageView}}&nbsp;阅读</span>
						</div>
					</div>
					<div class="art_word">
						<div v-html="artDetail.content" class="rich_text"></div>
					</div>
				</div>
				<div class="art_right">
					<div class="host_title">热门推荐</div>
					<div class="host_list" v-for="(item, index) in artList" :key="item.informationId">
					    <span class="num" :class="`num${index+1}`">{{ index + 1 }}</span>
						<a target="_blank" :href="`/info/operateDetail?type=${4}&informationId=${item.informationId}`" class="desc">{{item.title}}</a>
					</div>
				</div>			
			</div>
		</div>
    </div>
</template>
<script>
import { ref, reactive, onMounted, getCurrentInstance, toRefs } from 'vue';
// import { ElMessage } from 'element-plus'
import { useRoute, useRouter } from "vue-router";
// import { useStore } from "vuex";


export default {
    name: 'hitDetail',
    components: {
    },
    setup() {
		const userPay = ref(false);
		const route = useRoute();
		const router = useRouter();
		const { proxy } = getCurrentInstance();
		// const conheight = ref(window.innerHeight - 369);
		const artList = ref([]);
		const state = reactive({
		     artDetail: {},
		     goodsList: [],
		     categoryPath: [],
		});
		const informationId = route.query.informationId || '';
		const conheight = window.innerHeight - 193;
		const getArtDetail = () => {
			proxy.$get("v3/cms/front/information/detail", { informationId }).then(res => {
			  if (res.state == 200) {
				  const data = res.data || {};
				  data.content = data.content.replace(/\<img/g, "<img style='width: 100%'")
				  state.artDetail = data;
				  
				  recomList();
			  }
			});
		}
		const toCollect = () => {
		     proxy.$get('v3/cms/front/info/followHyInformation', { informationId }).then(res => {
		          if (res.state == 200) {
		  				getArtDetail();
		          }
		     })
		}
		const toNotCollect = () => {
		     proxy.$get('v3/cms/front/info/cancelFollowHyInformation', { informationId }).then(res => {
		          if (res.state == 200) {
		  				getArtDetail();
		          }
		     })
		}
		const recomList = () => {
		     let param = {
		     	  current: 1,
		          categoryId: state.artDetail.categoryId
		     }
		     proxy.$get('v3/cms/front/information/informationList', param).then(res => {
		          if (res.state == 200) {
					   const informationList = (res.data.list[0] || {}).informationList || [];
		               artList.value = informationList.filter(l => l.isRecommend == 1).sort((a, b) => b.pageView - a.pageView).slice(0, 10);
		          }
		     })
		}
		const clickNum = () => {
		     proxy.$post('v3/cms/front/information/updateClickNum', { informationId }).then(res => {
		          if (res.state == 200) {}
		     })
		}
        onMounted(() => {
		  getArtDetail();
		  clickNum();
        })
        //返回值
        return {
			toCollect,
			toNotCollect,
			...toRefs(state),
			artList,
			userPay,
			conheight,
        }
    }
}


</script>

<style lang="scss" scoped>
.container{
	padding-top: 15px;
	background-color: #F9F9F9;
	overflow-y: auto;
	.content{
		width: 1210px;
		margin: 0 auto;
		.article{
			display: flex;
			justify-content: space-between;
			margin-top: 20px;
			.txt2 {
			     margin-left: 5px;
			     font-size: 14px;
			     color: #AEAEAE;
			}
			.el-icon-star-off,
			.el-icon-view {
			     margin-left: 20px;
			}
			.art_left{
				flex: 1;
				background-color: #fff;
				margin-right: 20px;
				padding: 40px 20px 10px;
				.art_title{
					font-weight: bold;
					font-size: 18px;
				}
				.art_tips{
					color: #B9B9B8;
					margin: 20px 0;
					display: flex;
					justify-content: space-between;
					padding-left: 30px;
					font-size: 14px;
					align-items: center;
					height: 25px;
					&>div{
						height: 100%;
						display: flex;
						align-items: center;
					}
				}
				.art_word{
					line-height: 24px;
				}
			}
			.art_right {
				background-color: #fff;
				padding: 20px;
				height: 300px;
				width: 385px;
					.host_title {
					    font-size: 22px;
					    font-weight: bold;
					    color: #101010;
					}
												
					.host_list {
					    display: flex;
					    align-items: center;
					    margin-top: 10px;
					    cursor: pointer;
					    .num {
					        display: flex;
					        align-items: center;
					        justify-content: center;
					        width: 25px;
					        height: 25px;
					        border-radius: 5px;
					        background-color: #9D9D9D;
					        color: #fff;
					        font-size: 16px;
					        font-weight: bold;
					    }
												
					    .desc {
					        width: 310px;
					        overflow: hidden;
					        text-overflow: ellipsis;
					        white-space: nowrap;
					        font-size: 14px;
					        color: #101010;
					        margin-left: 10px;
					        &:hover {
					            text-decoration: underline;
					        }
					    }
												
					    .num1 {
					        background-color: #FF6660 !important;
					    }
					    .num2 {
					        background-color: #FFA560 !important;
					    }
					    .num3 {
					        background-color: #FFB99E !important;
					    }
					}
				}
		}
		
	}
}
:deep(.el-breadcrumb__item:last-child .el-breadcrumb__inner){
	font-weight: 700;
	color: var(--el-text-color-primary);
}
:deep(.el-breadcrumb__separator){
	margin: 0;
	color: var(--el-text-color-primary);
}
.word_red {
    color: #D80011 !important;
}
</style>