<template>
	<div class="info_top">
		    <img src="@/assets/iconPark-buy.png" alt="">
		    <span class="title">行业资讯</span>
		</div>
	<div class="container">
		<div class="industry_knowledge">
			<template v-for="(item, index) in artList" :key="index">
				<div class="information" v-if="item.comList.length">
				    <div class="information_title">
				        <div class="top_left">
				            <div class="top_left_title">{{item.categoryName}}</div>
				        </div>
				        <div class="top_right word_cur" @click="routerPush({ path: '/info/knowledgeList', query:{ categoryId: item.categoryId, tit: item.categoryName } })">查看全部>></div>
				    </div>
						
				    <div class="industry_knowledge_content">
				        <div class="industry_left">
				            <div class="industry_left_itemBox" @click="toOperateDeatil({ type: 4, informationId: item.informationId })" v-for="(item,index) in item.comList.slice(0,6)" :key="index">
                              <div class="industry_left_card">
								<div class="card_top">
				                    <div class="title overflow_ellipsis_two">{{item.title}}</div>
				                    <div class="desc overflow_ellipsis_two">{{item.introduction}}</div>
				                </div>
				                <div class="time">{{item.createTime}}</div>
							 </div>
                            <img :src="item.coverImage" alt="" class="industry_right_img">
							</div>
				        </div>
				        <div class="industry_right">
				            <div class="host_title">热门推荐</div>
				            <div class="host_list" v-for="(item1,index1) in item.recomList" :key="index1">
				                <span class="num" :class="`num${index1+1}`">{{ index1 + 1 }}</span>
								<a target="_blank" :href="`/info/operateDetail?type=${4}&informationId=${item1.informationId}`" class="desc">{{item1.title}}</a>
				            </div>
				        </div>
				    </div>
				</div>
			</template>
	</div>
   </div>
</template>
<script>
import { ref, reactive,onMounted, getCurrentInstance } from 'vue';
// import { ElMessage } from 'element-plus'
import { useRouter } from "vue-router";
// import { useStore } from "vuex";


export default {
    name: 'induKnowledge',
    components: {
    },
    setup() {
		const { proxy } = getCurrentInstance();
		const router = useRouter();
		const infoArr = reactive(['1'])
		const params = reactive({
		    current: 1,
			pageSize: 1000,
		});
		const artList = ref([]);
		// 路由跳转
		const routerPush = (params = {}) => router.push(params);
		const toOperateDeatil = (param ={}) => router.push({ path: '/info/operateDetail/', query:param });
		const getArtList = () => {
		    // params.categoryId = route.query.from ? 0 : route.query.categoryId;
			// params.categoryId = 0;
		    proxy.$get('v3/cms/front/information/informationList', params).then(res => {
		        if (res.state == 200) {
		            artList.value = res.data.list
		            artList.value.map(item => {
		                item.comList = item.informationList
		                item.recomList = item.informationList.filter(l => l.isRecommend == 1).sort((a, b) => b.pageView - a.pageView).slice(0, 10)
		            })
		        }
		    })
		};
        onMounted(() => {
			getArtList();
        })
        //返回值
        return {
			artList,
			infoArr,
			toOperateDeatil,
			routerPush,
        }
    }
}


</script>

<style lang="scss" scoped>
	.container{
		background-color: #F9F9F9;
	}
	.industry_knowledge {
			width: 1210px;
			margin: 0 auto;
			padding-top: 20px;
			.information {
					margin-bottom: 20px;
					background-color: #fff;
				    .information_title {
						height: 50px;
				        display: flex;
				        align-items: center;
				        justify-content: space-between;
						border-left: 6px solid #D80011;
						border-bottom: 1px solid #F0F0F0;
						padding-right: 20px;
				        .top_left {
				            display: flex;
				            align-items: center;
				
				            .top_left_title {
				                // width: 128px;
				                font-size: 22px;
				                color: #101010;
				                font-weight: bold;
				                padding: 0 20px;
				            }
				
				            .top_left_info {
				                font-size: 14px;
				                color: #F40B07;
				                padding: 2px 20px;
				                border: 1px solid #F40B07;
				                border-radius: 4px;
				            }
				        }
				
				        .top_right {
				            font-size: 14px;
				            color: #6A6A6A;
				
				        }
				    }
					.industry_knowledge_content {
					    display: flex;
					    padding: 25px;
						
					    .industry_left {
					        display: flex;
					        flex-wrap: wrap;
					        width: 775px;
							.industry_left_itemBox{
								width: calc(50% - 20px);
								display: flex;
								justify-content: space-between;
								padding: 10px;
							}
							.industry_right_img{
								height: 110px;
								width: 160px;
							}
					        .industry_left_card {
					            flex: 1;
                    			height: 110px;
					            cursor: pointer;
					            display: flex;
					            flex-direction: column;
					            justify-content: space-between;
                   				margin-right: 8px;
					            &:hover {
					                box-shadow: 0 0 5px #ccc;
					            }
						
					            &:nth-child(2n) {
					                margin-left: 20px;
					            }
						
					            .card_top {
					                .title {
					                    color: #101010;
					                    font-size: 16px;
					                    width: 100%;
					                }
						
					                .desc {
					                    width: 100%;
					                    font-size: 14px;
					                    margin-top: 10px;
					                    color: #8C8C8C;
					                }
					            }
						
					            .time {
					                color: #8C8C8C;
					                font-size: 14px;
					            }
					        }
					    }
						
					    .industry_right {
					        .host_title {
					            font-size: 22px;
					            font-weight: bold;
					            color: #101010;
					        }
						
					        .host_list {
					            display: flex;
					            align-items: center;
					            margin-top: 10px;
					            cursor: pointer;
					            .num {
					                display: flex;
					                align-items: center;
					                justify-content: center;
					                width: 25px;
					                height: 25px;
					                border-radius: 5px;
					                background-color: #9D9D9D;
					                color: #fff;
					                font-size: 16px;
					                font-weight: bold;
					            }
						
					            .desc {
					                width: 310px;
					                overflow: hidden;
					                text-overflow: ellipsis;
					                white-space: nowrap;
					                font-size: 14px;
					                color: #101010;
					                margin-left: 10px;
					                &:hover {
					                    text-decoration: underline;
					                }
					            }
						
					            .num1 {
					                background-color: #FF6660 !important;
					            }
					            .num2 {
					                background-color: #FFA560 !important;
					            }
					            .num3 {
					                background-color: #FFB99E !important;
					            }
					        }
					    }
					}
				 
				}
	    }
	.info_top {
	    --bgColor: #BD3124;
	    width: 100%;
	    height: 176px;
	    background-color: var(--bgColor);
	    display: flex;
	    align-items: center;
	    justify-content: center;
	
	    img {
	        width: 41px;
	        height: 41px;
	    }
	
	    span {
	        font-size: 36px;
	        color: #fff;
	        font-weight: bold;
	        margin-left: 20px;
	    }
	}


.word_red {
    color: #D80011 !important;
}

.word_cur {
    cursor: pointer;
}

.flex_two {
    display: flex;
    justify-content: space-between;
}
</style>