<template>
    <orderDetail v-if="Number(type) === 0"/>
	<supplyDetail v-else-if="Number(type) === 1"/>
	<inviteDetail v-else-if="Number(type) === 2"/>
	<hitDetail v-else-if="Number(type) === 3"/> 
	<knowledgeDetail v-else-if="Number(type) === 4"/>
</template>
<script>
import { ref, onMounted } from 'vue';
import orderDetail from './orderDetail';
import supplyDetail from './supplyDetail';
import inviteDetail from './inviteDetail';
import hitDetail from './hitDetail';
import knowledgeDetail from './knowledgeDetail';

import { useRoute, useRouter } from "vue-router";
// import { ElMessage } from 'element-plus'
// import { useStore } from "vuex";


export default {
    name: 'operateDetail',
    components: {
		orderDetail,
		supplyDetail,
		inviteDetail,
		hitDetail,
		knowledgeDetail,
    },
    setup() {
		const route = useRoute()
		const type = route.query.type || 0;
        onMounted(() => {
			
        })
        //返回值
        return {
			type,
        }
    }
}


</script>

<style lang="scss" scoped>

</style>