<template>
    <div class="info">
        <div class="info_top">
            <img src="@/assets/iconPark-buy.png" alt="">
            <span class="title">采购中心</span>
        </div>
        <div class="info_center">
            <div class="info_center_top">
                <div class="center_top_left">
                    <span>产品名称：</span>
                    <input type="text" placeholder="请输入" v-model="searchName" />
                    <div class="searchBtn cursorPointer" @click="() => searchList()">查询</div>
                    <div class="closehBtn cursorPointer" @click="() => {searchName = '';searchList();}">重置</div>
                </div>
                <div class="center_top_right" @click="toPublish">发布采购信息</div>
            </div>

            <!-- 内容 -->
            <div class="info_center_table">
                <el-table class="center_table" :data="dataSource.data" style="width: 100%">
                    <el-table-column label="产品名称" width="250" align="center">
                        <template #default="{row}">
							<router-link class="word_red word_cur overflow_ellipsis" :to="{ path: '/info/operateDetail/', query:{ type: 0, procureSn: row.procureSn }}">{{ row.productName }}</router-link>
                        </template>
                    </el-table-column>
                    <el-table-column prop="specValue" label="规格参数" width="300" align="center">
                        <template #default="{row}">
							<span class="overflow_ellipsis_two">{{ row.specValue }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="procureNum" label="采购数量" width="80" align="center" />
                    <el-table-column prop="procureUnit" label="产品单位" width="100" align="center" />
                    <el-table-column prop="contacts" label="联系人" width="120" align="center" />
                    <el-table-column prop="telephone" label="联系电话" width="130" align="center" />
                    <el-table-column prop="createTime" label="发布时间" width="160" align="center" />
                    <el-table-column label="操作" align="center">
                        <template #default="{row}">
							<router-link class="word_red word_cur" :to="{ path: '/info/operateDetail/', query:{ type: 0, procureSn: row.procureSn }}">查看详情</router-link>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="pagination_btn">
                <el-pagination
                @current-change="handleCurrentChange" v-model:currentPage="currentPage"
                 :page-size="20" background layout="prev, pager, next" :total="dataSource.pagination.total" />
            </div>
        </div>
    </div>
</template>
<script>
import { onMounted, reactive, ref, computed, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router'

export default {
    name: 'orderCenter',
    components: {
    },
    setup() {
		const dataSource = reactive({ data : [], pagination : {} });
		const searchName = ref('');
		const currentPage = ref(1);

		const router = useRouter()
		const { proxy } = getCurrentInstance();
		const searchList = () => {
			proxy.$get("v3/system/front/pcDeco/procureList", { productName: searchName.value, pageSize: 20, current: currentPage.value }).then(res => {
			  if (res.state == 200) {
					const data = res.data || {};
					dataSource.data = data.list || [];
					dataSource.pagination = data.pagination || {};
			  }
			});
		}
        const handleCurrentChange = (page) => {
            currentPage.value = page;
            searchList();
        }
		const toPublish = () => router.push({ path: '/member/orderInfo', query: { showPub: 1 }});
		searchList();
        return {
            handleCurrentChange,
            currentPage,
			toPublish,
			searchName,
			dataSource,
			searchList,
        }
    }
}


</script>

<style lang="scss" scoped>
.cursorPointer{
	cursor: pointer;
}
.info {
    .info_top {
        --bgColor: #007B06;
        width: 100%;
        height: 176px;
        background-color: var(--bgColor);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 41px;
            height: 41px;
        }

        span {
            font-size: 36px;
            color: #fff;
            font-weight: bold;
            margin-left: 20px;
        }
    }

    .info_center {
        width: 1280px;
        margin: 30px auto 0;
        .info_center_top {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 20px;
            .center_top_left {
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #101010;

                span {}

                input {
                    width: 306px;
                    height: 30px;
                    padding: 0 10px;
                    border: 1px solid #BBBBBB;
                }

                .searchBtn {
                    width: 50px;
                    height: 24px;
                    border-radius: 3px;
                    color: #fff;
                    font-size: 12px;
                    background-color: #E2231A;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 10px;
                }

                .closehBtn {
                    width: 50px;
                    height: 24px;
                    border-radius: 3px;
                    color: #333333;
                    font-size: 12px;
                    border: 1px solid #CECECE;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 10px;
                }
            }

            .center_top_right {
                padding: 5px 15px;
                border: 1px solid #E2231A;
                color: #E2231A;
                border-radius: 3px;
                cursor: pointer;
            }
        }

        .info_center_table {
            margin-top: 30px;
        }
    }
}
.pagination_btn {
    margin: 50px 0;
}

:deep(.el-table) {
    th {
        background-color: #f5f5f5 !important;
    }
    th,td {
        border: none !important;
    }
    &::before {
        display: none !important;
    }
}

.word_red {
    color: #D80011 !important;
    width: 100%;
    display: inline-block;
}
.word_cur {
    cursor: pointer;
}
</style>