<template>
    <div class="container" :style="{ height: `${conheight}px`}">
		<div class="content">
			<el-breadcrumb separator=">">
			  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			  <el-breadcrumb-item :to="{ path: '/info/induKnowledge' }">行业资讯</el-breadcrumb-item>
			  <el-breadcrumb-item>{{ tit }}</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="article">
				<div class="art_left">
					<template v-for="(item3,index3) in artList.list" :key="index3">
					     <informationItem :item3="item3"></informationItem>
					</template>
				</div>
				<div class="art_right">
					<div class="host_title">热门推荐</div>
					<div class="host_list" v-for="(item, index) in artList.recomList" :key="item.informationId">
					    <span class="num" :class="`num${index+1}`">{{ index + 1 }}</span>
						<a target="_blank" :href="`/info/operateDetail?type=${4}&informationId=${item.informationId}`" class="desc">{{item.title}}</a>
					</div>
				</div>			
			</div>
		</div>
    </div>
</template>
<script>
import { ref, reactive, onMounted, getCurrentInstance, toRefs } from 'vue';
import informationItem from './informationItem'
import { useRoute, useRouter } from "vue-router";


export default {
    name: 'hitDetail',
    components: {
		informationItem
    },
    setup() {
		const userPay = ref(false);
		const route = useRoute();
		const router = useRouter();
		const { proxy } = getCurrentInstance();
		const tit = route.query.tit ? route.query.tit : '列表';
		const artList = reactive({
		     list: [],
		     recomList: [],
		})
		const conheight = window.innerHeight - 193;
		
		const param = reactive({
		     current: 1,
		    //  pageSize: 10,
		
		})
		const state = reactive({
		     page: {}
		})
		
		const recomList = () => {
		     let param = {
		          isRecommend: 1
		     }
		     proxy.$get('v3/cms/front/information/informationList', param).then(res => {
		          if (res.state == 200) {
					  let informationLists = [];
					  const list = res.data.list || [];
					  list.map((item) => {
						  if(item.informationList.length > 0) informationLists = [...informationLists, ...item.informationList];
					  });
		               artList.recomList = informationLists.slice(0, 10);
		          }
		     })
		}
		
		const getArtList = () => {
		     if (route.query.categoryId) param.categoryId = route.query.categoryId;
		     proxy.$get('v3/cms/front/information/informationList', param).then(res => {
		          if (res.state == 200) {
					   const informationList = (res.data.list[0] || {}).informationList || [];
		               artList.list = informationList;
		               artList.recomList = informationList.filter(l => l.isRecommend == 1).sort((a, b) => b.pageView - a.pageView).slice(0, 10);
		               state.page = res.data.pagination;
		          }
		     })
		
		}
		
        onMounted(() => {
			getArtList();
        })
        //返回值
        return {
			tit,
			artList,
			userPay,
			conheight,
        }
    }
}


</script>

<style lang="scss" scoped>
.container{
	padding-top: 15px;
	background-color: #F9F9F9;
	overflow-y: auto;
	.content{
		width: 1210px;
		margin: 0 auto;
		.article{
			display: flex;
			justify-content: space-between;
			margin-top: 20px;
			.art_left{
				flex: 1;
				background-color: #fff;
				margin-right: 20px;
				
			}
			.art_right {
				background-color: #fff;
				padding: 20px;
				max-height: 415px;
				width: 385px;
					.host_title {
					    font-size: 22px;
					    font-weight: bold;
					    color: #101010;
					}
												
					.host_list {
					    display: flex;
					    align-items: center;
					    margin-top: 10px;
					    cursor: pointer;
					    .num {
					        display: flex;
					        align-items: center;
					        justify-content: center;
					        width: 25px;
					        height: 25px;
					        border-radius: 5px;
					        background-color: #9D9D9D;
					        color: #fff;
					        font-size: 16px;
					        font-weight: bold;
					    }
												
					    .desc {
					        width: 310px;
					        overflow: hidden;
					        text-overflow: ellipsis;
					        white-space: nowrap;
					        font-size: 14px;
					        color: #101010;
					        margin-left: 10px;
					        &:hover {
					            text-decoration: underline;
					        }
					    }
												
					    .num1 {
					        background-color: #FF6660 !important;
					    }
					    .num2 {
					        background-color: #FFA560 !important;
					    }
					    .num3 {
					        background-color: #FFB99E !important;
					    }
					}
				}
		}
		
	}
}
:deep(.el-breadcrumb__item:last-child .el-breadcrumb__inner){
	font-weight: 700;
	color: var(--el-text-color-primary);
}
:deep(.el-breadcrumb__separator){
	margin: 0;
	color: var(--el-text-color-primary);
}
.word_red {
    color: #D80011 !important;
}
</style>