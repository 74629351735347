<template>
<div class="scrollBox" :style="`max-height:${itemHeight*maxLines}px`" ref="table" @mouseenter="enter" @mouseleave="leave">
    <template v-if="dataLength>maxLines">
        <slot/>
        <slot/>
    </template>
    <template v-else>
        <slot/>
    </template>
</div>
</template>
<script>
  import { reactive, getCurrentInstance, ref, onMounted, onUnmounted } from "vue";

  export default {
    name: "scrollAutoTable",
    props: {
            itemHeight: { // 每行的高度
                type: Number,
                default: 47,
            },
            maxLines: { // 最多多少行
                type: Number,
                default: 8,
            },
            dataLength: { // 多少条数据
                type: Number,
                default: 8,
            },
    },
    setup(props) {
       const { proxy } = getCurrentInstance();
       const { itemHeight, dataLength, maxLines } = props;

       let timer = null;
       let scrollTop = 0;

       const enter = () => {
           if (timer) {
                clearInterval(timer);
                timer = null;
            }
       }
       const leave = () => {
           if(dataLength > maxLines)startScroll();
       }
       const startScroll = ()=>{
           const table = proxy.$refs.table;
                timer = setInterval(() => {
                scrollTop++;
                table.scrollTop =scrollTop;
                const height = dataLength * itemHeight;
                if(scrollTop === height){
                    scrollTop= 0;
                    table.scrollTop =scrollTop;
                }
            }, 50);  
       }
       onMounted(() => {
           if(dataLength > maxLines)startScroll();
        })
        onUnmounted(() => {
            if (timer) {
                clearInterval(timer);
                timer = null;
            }
        });

      return {
          enter,
          leave,
      };
    }
  };
</script>
<style lang="scss" scoped>
 .scrollBox{
     overflow: hidden;
 }
</style>