<template>
    <div class="container">
          <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/info/inviteCenter' }">招标中心</el-breadcrumb-item>
            <el-breadcrumb-item>招标详情</el-breadcrumb-item>
          </el-breadcrumb>
		  <div v-show="showInfo">
			  <div class="noPay" v-show="showToPay">
			  			  <div style="text-align: center;">
								<p style="margin-bottom: 10px;">该信息为付费内容,需支付<span class="word_red">￥{{price}}.00</span>后进行查看。</p>
								<p style="margin-bottom: 10px;">或联系客服升级权限后进行查看</p>
								<p style="margin-bottom: 20px;">0318-7962000 0318-7962111</p>
			  				  <el-button class="toPay" size="small" @click="toPay">去支付</el-button>
			  			</div>
			  </div>
			  <div class="payed" v-show="!showToPay">
			  	<div class="p_item">
			  		<div class="p_name">
			  			标题
			  		</div>
			  				<div class="p_value">
			  					{{ dataResource.resource.title ||  ''}}
			  				</div>
			  	</div>
			  			<div class="p_item">
			  				<div class="p_name">
			  					发布时间
			  				</div>
			  				<div class="p_value">
			  					{{ formatTableTime(dataResource.resource.releaseTime) }}
			  				</div>
			  			</div>
			  			<div class="p_item">
			  				<div class="p_name">
			  					截止时间
			  				</div>
			  				<div class="p_value">
			  					{{ notPay ? hidTime(formatTableTime(dataResource.resource.endTime)) : formatTableTime(dataResource.resource.endTime) }}
								<a class="word_red" style="marginLeft: 4px;" v-show="notPay" @click="toLook()">查看</a>
			  				</div>
			  			</div>
			  			
			  			<div class="p_item">
			  				<div class="p_name">
			  					采购单位
			  				</div>
			  				<div class="p_value">
			  					{{ dataResource.resource.procureUnit ||  ''}}
								<a class="word_red" style="marginLeft: 4px;" v-show="notPay" @click="toLook">查看</a>
			  				</div>
			  			</div>
						  <div class="p_item">
			  				<div class="p_name">
			  					联系人
			  				</div>
			  				<div class="p_value">
			  					{{ dataResource.resource.contacts ||  ''}}
								<a class="word_red" style="marginLeft: 4px;" v-show="notPay" @click="toLook">查看</a>
			  				</div>
			  			</div>
			  			<div class="p_item">
			  				<div class="p_name">
			  					联系电话
			  				</div>
			  				<div class="p_value">
			  					{{ dataResource.resource.phone ||  ''}}
								<a class="word_red" style="marginLeft: 4px;" v-show="notPay" @click="toLook">查看</a>
			  				</div>
			  			</div>
			  			<div class="p_item">
			  				<div class="p_name">
			  					正文内容
			  				</div>
			  				<div class="p_value">
								<div v-show="!notPay" v-html="dataResource.resource.content" class="rich_text"></div>
								<a class="word_red" v-show="notPay" @click="toLook">查看</a>
			  				</div>
			  			</div>
			  			<div class="p_item" style="margin-top: 30px;" v-if="(dataResource.resource.annexUrl || []).length > 0">
			  				<div class="p_name">
			  					附件
			  				</div>
			  				<div class="p_value">
								  <div v-show="!notPay" class="fj_item" v-for="(i, index) in dataResource.resource.annexUrl.length" :key="index">
									    <div class="title"><img src="@/assets/word2.png" alt="" />{{ dataResource.resource.annex[index] }}</div>
			  							<a @click="downLoadurl(dataResource.resource.annexUrl[index])" style="color: red;cursor: pointer;">下载</a>
								  </div>
								<a class="word_red" v-show="notPay" @click="toLook">查看</a>
			  				</div>
			  			</div>
						  <div class="p_item" style="margin-top: 30px;" v-else>
			  				<div class="p_name">
			  					附件
			  				</div>
			  				<div class="p_value">
								  <span v-show="!notPay">无</span>
								  <a class="word_red" v-show="notPay" @click="toLook">查看</a>
			  				</div>
			  			</div>

			  </div>
		  </div>
    </div>
</template>
<script>
import { ref, reactive, onMounted, getCurrentInstance } from 'vue';
// import { ElMessage } from 'element-plus'
import { useRoute, useRouter } from "vue-router";

// import { useStore } from "vuex";


export default {
    name: 'inviteDetail',
    components: {
    },
    setup() {
		const route = useRoute();
		const router = useRouter();
		const { proxy } = getCurrentInstance();
		const dataResource = reactive({ resource: {} });
		const tenderSn = route.query.tenderSn || '';
		const showToPay = ref(false);
		const showInfo = ref(false);

		const notPay = ref(true);

		const price = ref('');
		const downLoadurl  = url => window.open(url);

        const formatTableTime = proxy.$formatTableTime;
		const hidTime = (str) => {
			const index = str.indexOf('年');
			if (index>-1) return `${str.slice(0, index)}年XX月XX日`;
			return 'XX年XX月XX日';
		}
		const toPay = () => {
			const param = { procurementSn: tenderSn, procurementType: 1, orderFrom: 1 };
			proxy.$post("v3/business/front/orderOperate/submitInformation", param).then(res => {
				if(res.state == 200){
					const paySn = (res.data || {}).paySn;
					// 跳转支付
					router.push({
					  path: "/buy/Pay",
					  query: {
					    paySn: paySn,
					    payFrom: 1,
					    ifcart: false,
						fromInfoDetail: 1,
						fromInfoParam: JSON.stringify({ type: 2, key: 'tenderSn', value: tenderSn }),
					  }
					});
			  }
			});
		}
		const toLook = () => {
			showToPay.value = true;
		}
        onMounted(() => {
			proxy.$get("v3/system/front/pcDeco/tenderDetail", { tenderSn }).then(res => {
				  showInfo.value = true;
				  if(res.state == 200){
					  const data = res.data || {};
					  const annex = (data.annex || '').split(',');
					  data.content = data.content.replace(/\<img/g, "<img style='width: 100%'")
					  dataResource.resource = {...data, annex };
					  notPay.value = false;
				  } else{
					  const data = (res.data || {}).hyTender || {};
					  const annex = (data.annex || '').split(',');
					  dataResource.resource = {...data, annex };
					  price.value = (res.data || {}).msg;
					  notPay.value = true;
				  }
			});	
        })
        //返回值
        return {
			showInfo,
			hidTime,
			notPay,
			toLook,
			formatTableTime,
			downLoadurl,
			toPay,
			price,
			showToPay,
			dataResource
        }
    }
}


</script>

<style lang="scss" scoped>
.container{
	width: 1210px;
	margin: 0 auto;
	padding-top: 15px;
	.fj_item{
		text-align: center;
		min-width:100px;
		display: inline-block;
		margin-right: 4px;
		.title{
			margin-bottom: 8px;
			display: flex;
			align-items: center;
			img{
				width: 20px;
				height: 20px;
			}
		}
	}
	.payed{
		width: 1000px;
		margin-top: 30px;
		margin-left: 20px;
		.p_item{
			display: flex;
			a{
				cursor: pointer;
			}
			.p_name{
				width: 105px;
				height: 53px;
				font-size: 14px;
				text-align: center;
				font-weight: 700;
				margin-right: 80px;
				text-align: right;
				flex-shrink: 0;
			}
			.p_value{
				width: 1100px;
				text-align: left;
			}
		}
		
	}
	.noPay{
		width: 690px;
		height: 200px;
		margin:120px auto 0;
		text-align: center;
		font-weight: 500;
		font-size: 18px;
		padding-top: 50px;
		.toPay{
			background-color: #D80011;
			color: #ffffff;
		    width: 365px;
		}
	}
}
:deep(.el-breadcrumb__item:last-child .el-breadcrumb__inner){
	font-weight: 700;
	color: var(--el-text-color-primary);
}
:deep(.el-breadcrumb__separator){
	margin: 0;
	color: var(--el-text-color-primary);
}
.word_red {
    color: #D80011 !important;
}
</style>