<!--个人中心左侧公共导航 @zjf-2020-12-26-->
<template>
  <div class="sld_member_left_nav">
    <template v-for="(item,index) in leftNavMenu" :key="index">
      <router-link :to="{path:item.path}" class="menu">
        <i :class="{iconfont:true,[item.icon]:true}"></i>
        {{item.name}}
      </router-link>
      <template v-if="item.child.length">
        <template v-for="(item_child,index_child) in item.child" :key="index_child">
          <router-link v-if="testIfShow(item_child)" :to="{path:item_child.path,query:item_child.query}"
            :class="{submenu:true,cur_code_active:curCode == item_child.code}">{{item_child.name}}</router-link>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
  import { getCurrentInstance, watchEffect, ref } from "vue";
  import { useRouter } from "vue-router";
  import { useStore } from "vuex";
  export default {
    name: "MemberLeftNav",
    components: {},
    setup() {
      const store = useStore();
      const router = useRouter();
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const curCode = ref("");
      watchEffect(() => {
        let tmpPath = router.currentRoute.value.fullPath;
        if (
          router.currentRoute.value.name != undefined &&
          router.currentRoute.value.name
        ) {
          curCode.value = router.currentRoute.value.name;
          if (tmpPath == "/member/collect?type=store") {
            curCode.value = "memberCollectStore"; //关注店铺
          } else if (
            tmpPath == "/member/order/aftersales?type=return" ||
            tmpPath.indexOf("/member/order/refund/detail?curTitleId=2") > -1
          ) {
            curCode.value = "AfterSalesListReturn"; //我的退货
          } else if (
            tmpPath == "/member/order/aftersales" ||
            tmpPath.indexOf("/member/order/refund/detail?curTitleId=1") > -1
          ) {
            curCode.value = "AfterSalesList"; //我的退款
          }
        }
      });
      const leftNavMenu = [
        {
          code: "tarde",
          icon: "icon-gerenzhongxinjiaoyizhongxin",
          name: L["交易中心"],
          path: "/member/index",
          child: [
            {
              code: "memberOrderList",
              name: L["我的订单"],
              path: "/member/order/list"
            },
            {
              code: "MemberOrderEvaluation",
              name: L["交易评价/晒单"],
              path: "/member/order/evaluation"
            },
            {
              code: "pointOrderList",
              name: "积分兑换订单",
              path: "/member/pointOrder/list"
            }
          ]
        },
		{
		  code: "orderInfo",
		  icon: "icon-gerenzhongxinguanzhu",
		  name: L["采购信息"],
		  path: "/member/index",
		  child: [
		    {
		      code: "MemberOrderInfo",
		      name: L["我的采购"],
		      path: "/member/orderInfo"
		    },
		  ]
		},
        {
          code: "collect",
          icon: "icon-gerenzhongxinguanzhu",
          name: L["关注中心"],
          path: "/member/index",
          child: [
            {
              code: "MemberCollect",
              name: L["我的收藏"],
              path: "/member/collect"
            },
            {
              code: "memberCollectStore",
              query: { type: "store" },
              name: L["关注店铺"],
              path: "/member/collect"
            },
            {
              code: "MemberFootprint",
              name: L["我的足迹"],
              path: "/member/footprint"
            }
          ]
        },
        {
          code: "service",
          icon: "icon-gerenzhongxinkehufuwu",
          name: L["客户服务"],
          path: "/member/index",
          child: [
            {
              code: "AfterSalesList",
              name: L["我的退款"],
              path: "/member/order/aftersales"
            },
            {
              code: "AfterSalesListReturn",
              query: { type: "return" },
              name: L["我的退货"],
              path: "/member/order/aftersales"
            }
          ]
        },
        {
          code: "member",
          icon: "icon-gerenzhongxinhuiyuanzhongxin",
          name: L["会员中心"],
          path: "/member/index",
          child: [
            {
              code: "MemberInfo",
              name: L["会员信息"],
              path: "/member/info"
            },
            {
              code: "MemberAccount",
              name: L["账号安全"],
              path: "/member/account"
            },
            {
              code: "MemberPhoneMange",
              name: L["手机号管理"],
              path: "/member/phone"
            },
            {
              code: "memberEmailMange",
              name: L["邮箱管理"],
              path: "/member/email"
            },
            {
              code: "MemberLoginPassword",
              name: L["登录密码"],
              path: "/member/pwd/login"
            },
            {
              code: "MemberPayPassword",
              name: L["支付密码"],
              path: "/member/pwd/pay"
            },
            {
              code: "MemberResetPassword",
              name: L["重置密码"],
              path: "/member/pwd/reset"
            },
            {
              code: "memberAddressList",
              name: L["收货地址"],
              path: "/member/address/list"
            }
          ]
        },
        {
          code: "money",
          icon: "icon-gerenzhongxincaichanzhongxin",
          name: L["我的钱包"],
          path: "/member/index",
          child: [
            {
              code: "MemberBalance",
              name: L["我的余额"],
              path: "/member/balance"
            },
            {
              code: "MemberRecharge",
              name: L["账户充值"],
              path: "/member/recharge"
            },
            {
              code: "MemberCoupon",
              name: "我的优惠券",
              path: "/member/coupon"
            },
            {
              code: "MyPoint",
              name: "我的积分",
              path: "/member/myPoint"
            }
          ]
        }
      ];
      const testIfShow = nav_info => {
        if (nav_info.code == "MemberResetPassword") {
          if (store.state.memberInfo.hasPayPassword == 1) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      };
      return { leftNavMenu, curCode, testIfShow };
    }
  };
</script>

<style lang="scss" scoped>
  .sld_member_left_nav {
    background-color: #fff;
    color: #666;
    font-size: 12px;
    border: 1px solid #ebebeb;
    border-top: 1px solid #fff;
    margin-top: -1px;
    padding-bottom: 100px;
    font-family: MicrosoftYaHei;
    width: 180px;
    float: left;
    margin-bottom: 20px;

    .menu {
      margin: 10px 0;
      padding-left: 15px;
      font-size: 15px;
      font-weight: 600;
      font-family: MicrosoftYaHei-Bold;
      display: block;
      color: #666;

      i {
        font-size: 16px;
        font-weight: 400;
        color: #888;
        margin-right: 12px;
      }
    }

    .submenu {
      line-height: 26px;
      padding-left: 53px;
      color: #333333;
      display: block;

      &:hover {
        font-weight: bold;
        color: $colorMain2;
      }

      &.cur_code_active {
        font-weight: bold;
        color: $colorMain2;
      }
    }
  }
</style>