<template>
    <div class="container">
          <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/info/orderCenter' }">采购中心</el-breadcrumb-item>
            <el-breadcrumb-item>采购详情</el-breadcrumb-item>
          </el-breadcrumb>
		  <div v-show="showInfo">
			  <div class="noPay" v-if="!userPay">
			  			  <div style="text-align: center;">
			  				  <p style="margin-bottom: 10px;">该信息为付费内容,需支付<span class="word_red">￥{{price}}.00</span>后进行查看。</p>
								<p style="margin-bottom: 10px;">或联系客服升级权限后进行查看</p>
								<p style="margin-bottom: 20px;">0318-7962000 0318-7962111</p>
			  				  <el-button class="toPay" size="small" @click="toPay">去支付</el-button>
			  			</div>
			  </div>
			  <div class="payed" v-else>
			  	<div class="p_item">
			  		<div class="p_name">
			  			产品名称
			  		</div>
			  				<div class="p_value">
			  					{{ dataResource.resource['productName'] ||  ''}}
			  				</div>
			  	</div>
			  			<div class="p_item">
			  				<div class="p_name">
			  					规格参数
			  				</div>
			  				<div class="p_value">
			  					{{ dataResource.resource['specValue'] ||  ''}}
			  				</div>
			  			</div>
			  			<div class="p_item">
			  				<div class="p_name">
			  					采购数量
			  				</div>
			  				<div class="p_value">
			  					{{ dataResource.resource['procureNum'] ||  ''}}
			  				</div>
			  			</div>
			  			
			  			<div class="p_item">
			  				<div class="p_name">
			  					产品单位
			  				</div>
			  				<div class="p_value">
			  					{{ dataResource.resource['procureUnit'] ||  ''}}
			  				</div>
			  			</div>
			  			<div class="p_item">
			  				<div class="p_name">
			  					联系人
			  				</div>
			  				<div class="p_value">
			  					{{ dataResource.resource['contacts'] ||  ''}}
			  				</div>
			  			</div>
			  			<div class="p_item">
			  				<div class="p_name">
			  					联系电话
			  				</div>
			  				<div class="p_value">
			  					{{ dataResource.resource['telephone'] ||  ''}}
			  				</div>
			  			</div>
			  			<div class="p_item">
			  				<div class="p_name">
			  					发布时间
			  				</div>
			  				<div class="p_value">
			  					{{ dataResource.resource['createTime'] ||  ''}}
			  				</div>
			  			</div>
			  </div>
		  </div>
    </div>
</template>
<script>
import { ref, reactive, onMounted, getCurrentInstance } from 'vue';
// import { ElMessage } from 'element-plus'
import { useRoute, useRouter } from "vue-router";
// import { useStore } from "vuex";


export default {
    name: 'orderDetail',
    components: {
    },
    setup() {
		const route = useRoute();
		const router = useRouter();
		const { proxy } = getCurrentInstance();
		const dataResource = reactive({ resource: {} });
		const procureSn = route.query.procureSn || '';
		const userPay = ref(false);
		const showInfo = ref(false);
		const price = ref('');
		const toPay = () => {
			const param = { procurementSn: procureSn, procurementType: 4, orderFrom: 1 };
			proxy.$post("v3/business/front/orderOperate/submitInformation", param).then(res => {
				if(res.state == 200){
					const paySn = (res.data || {}).paySn;
					// 跳转支付
					router.push({
					  path: "/buy/Pay",
					  query: {
					    paySn: paySn,
					    payFrom: 1,
					    ifcart: false,
						fromInfoDetail: 1,
						fromInfoParam: JSON.stringify({ type: 0, key: 'procureSn', value: procureSn }),
					  }
					});
			  }
			});
		};
		onMounted(() => {
			proxy.$get("v3/system/front/pcDeco/procureDetail", { procureSn }).then(res => {
			  showInfo.value = true;
			  if (res.state == 200) {
					userPay.value = true;
					dataResource.resource = res.data || {};
			  }else{
				  price.value = (res.data || {}).msg;
			  }
			});	
		})
        //返回值
        return {
			price,
			toPay,
			showInfo,
			userPay,
			dataResource,
        }
    }
}


</script>

<style lang="scss" scoped>
.container{
	width: 1210px;
	margin: 0 auto;
	padding-top: 15px;
	.payed{
		width: 1000px;
		margin-top: 30px;
		margin-left: 20px;
		.p_item{
			display: flex;
			.p_name{
				width: 105px;
				height: 53px;
				font-size: 14px;
				text-align: center;
				font-weight: 700;
				margin-right: 80px;
				text-align: right;
				flex-shrink: 0;
			}
			.p_value{
				flex: 1;
				text-align: left;
			}
		}
		
	}
	.noPay{
		width: 690px;
		height: 200px;
		margin:120px auto 0;
		text-align: center;
		font-weight: 500;
		font-size: 18px;
		padding-top: 50px;
		.toPay{
			background-color: #D80011;
			color: #ffffff;
			width: 365px;
		}
	}
}
:deep(.el-breadcrumb__item:last-child .el-breadcrumb__inner){
	font-weight: 700;
	color: var(--el-text-color-primary);
}
:deep(.el-breadcrumb__separator){
	margin: 0;
	color: var(--el-text-color-primary);
}
.word_red {
    color: #D80011 !important;
}
</style>