<template>
  <el-dialog
    v-model="dialogVisible"
    width="40%"
    @close="close"
  >
  <div class="box">
      <div class="content">
          <div class="item">
          <span><span class="red">*</span>产品名称:</span>
          <input type="text" v-model="params.productName" maxlength="40" placeholder="请输入产品名称,最多40个字" />
      </div>
      <div class="item">
          <span><span class="red">*</span>规格参数:</span>
          <input type="text" v-model="params.specValue" maxlength="40" placeholder="请输入规格参数,最多40个字" />
      </div>
      <div class="item">
          <span><span class="red">*</span>企业名称:</span>
          <input type="text" v-model="params.enterprise" placeholder="请输入企业名称" />
      </div>
      <div class="item">
          <span><span class="red">*</span>联系人:</span>
          <input type="text" v-model="params.contacts" placeholder="请输入联系人"  />
      </div>
      <div class="item">
          <span><span class="red">*</span>联系电话:</span>
          <input type="text" v-model="params.telephone" placeholder="请输入联系电话"  />
      </div>
      </div>
      <div class="btn" @click="save">
          保存
      </div>
  </div>
  </el-dialog>
</template>

<script>
import { onMounted, reactive, ref, computed, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router'
import { ElMessage } from "element-plus";

export default {
    name: 'orderCenter',
    components: {
    },
    props: ['dialogVisible',],
    setup(props, { emit }) {
        const params = ref({});
		const { proxy } = getCurrentInstance();
        let clicked = false;
        const save = () => {
            if(!clicked){
                clicked = true;
                if (proxy.$helperIsEmpty(params.value.productName)) { ElMessage.error('请输入产品名称'); clicked = false; return false;}
                if (proxy.$helperIsEmpty(params.value.specValue)) { ElMessage.error('请输入规格参数'); clicked = false; return false;}
                if (proxy.$helperIsEmpty(params.value.enterprise)) { ElMessage.error('请输入企业名称'); clicked = false; return false;}
                if (proxy.$helperIsEmpty(params.value.contacts)) { ElMessage.error('请输入联系人'); clicked = false; return false;}
                if(proxy.$checkMobile(params.value.telephone) !==true){
                    ElMessage.error(proxy.$checkMobile(params.value.telephone));
                    clicked = false;
                    return false;
                }
                proxy.$get("v3/goods/front/goods/supplyPublish", params.value).then(res => {
                if (res.state == 200) {
                        ElMessage.success(res.msg);
                        close();
                        clicked = false;
                } else {
                        ElMessage.error(res.msg);
                        clicked = false;
                }
                });
            }
		}
        const close = () => {
            params.value={};
            emit('update:dialogVisible', false);
        }
        return {
            save,
            params,
            close,
        }
    }
}


</script>
<style lang="scss" scope>
     .el-dialog__header {
            display: none;
        }
    .box{
        .red{
            color: red;
        }
        .btn{
            width: 80px;
            height: 40px;
            background-color: #ff711e;
            line-height: 40px;
            color: #fff;
            text-align: center;
            margin-left: 100px;
            border-radius: 4px;
        }
        .content{
            padding: 15px 8px;
            .item{
                display: flex;
                align-items: center;
                margin-bottom: 12px;
                span{
                    width: 80px;
                    text-align: right;
                }
                input{
                    flex: 1;
                    margin-left: 10px;
                    height:40px;
                    border: 1px solid #b9bcc3;
                    padding-left: 10px;
                }
            }
        }
    }   
</style>
