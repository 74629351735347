<template>
    <div class="container">
          <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/info/supplyCenter' }">供应中心</el-breadcrumb-item>
            <el-breadcrumb-item>供应详情</el-breadcrumb-item>
          </el-breadcrumb>
		  <div class="payed">
		  	<div class="p_item">
		  		<div class="p_name">
		  			产品名称
		  		</div>
				<div class="p_value">
					{{dataResource.resource.productName || ''}}
				</div>
		  	</div>
			<div class="p_item">
				<div class="p_name">
					规格参数
				</div>
				<div class="p_value">
					{{dataResource.resource.specValue || ''}}
				</div>
			</div>
			<div class="p_item">
				<div class="p_name">
					企业名称
				</div>
				<div class="p_value">
					{{dataResource.resource.enterprise || ''}}
				</div>
			</div>
			
			<div class="p_item">
				<div class="p_name">
					联系人
				</div>
				<div class="p_value">
					{{dataResource.resource.contacts || ''}}
				</div>
			</div>
			<div class="p_item">
				<div class="p_name">
					联系电话
				</div>
				<div class="p_value">
					{{dataResource.resource.telephone || ''}}
				</div>
			</div>
			<div class="p_item">
				<div class="p_name">
					发布时间
				</div>
				<div class="p_value">
					{{dataResource.resource.createTime || ''}}
				</div>
			</div>
		  </div>
    </div>
</template>
<script>
import { ref, reactive, onMounted, getCurrentInstance } from 'vue';	
// import { ElMessage } from 'element-plus'
import { useRoute, useRouter } from "vue-router";
// import { useStore } from "vuex";


export default {
    name: 'supplyDetail',
    components: {
    },
    setup() {
		const route = useRoute();
		const { proxy } = getCurrentInstance();
		const dataResource = reactive({ resource: {} });
		const supplySn = route.query.supplySn || '';
		proxy.$get("v3/system/front/pcDeco/supplyDetail", { supplySn }).then(res => {
		  if (res.state == 200) {
				dataResource.resource = res.data || {};
		  }
		});
    
        //返回值
        return {
			dataResource,
        }
    }
}


</script>

<style lang="scss" scoped>
.container{
	width: 1210px;
	margin: 0 auto;
	padding-top: 15px;
	.payed{
		width: 1000px;
		margin-top: 30px;
		margin-left: 20px;
		.p_item{
			display: flex;
			.p_name{
				width: 105px;
				height: 53px;
				font-size: 14px;
				text-align: center;
				font-weight: 700;
				margin-right: 80px;
				text-align: right;
				flex-shrink: 0;
			}
			.p_value{
				flex: 1;
				text-align: left;
			}
		}
		
	}
	.noPay{
		width: 690px;
		height: 200px;
		margin:120px auto 0;
		text-align: center;
		font-weight: 500;
		font-size: 18px;
		padding-top: 50px;
		.toPay{
			background-color: #D80011;
			color: #ffffff;
			width: 102px;
		}
	}
}
:deep(.el-breadcrumb__item:last-child .el-breadcrumb__inner){
	font-weight: 700;
	color: var(--el-text-color-primary);
}
:deep(.el-breadcrumb__separator){
	margin: 0;
	color: var(--el-text-color-primary);
}
.word_red {
    color: #D80011 !important;
}
</style>