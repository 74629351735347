<template>
  <div class="sld_orderInfo_wrapper">
	 <MemberTitle :memberTitle="showpublish ? '发布采购需求':'我的采购'"></MemberTitle> 
	 <div class="container" v-show="!showpublish">
		 <div class="info_center_top">
		     <div class="center_top_left">
		         <span>采购单号：</span>
		         <input type="text" placeholder="请输入" v-model="searchParam.procureSn" />
				 &nbsp;&nbsp;&nbsp;&nbsp;
				 <span>状态：</span>
				 <select placeholder="请选择" v-model="searchParam.status">
					 <option :value="null">全部</option>
					 <option value="0">不通过</option>
					 <option value="1">通过</option>
					 <option value="2">审核中</option>
				 </select>
				 &nbsp;&nbsp;
		         <div class="searchBtn cursorPointer" @click="() => searchList()">查询</div>
		         <div class="closehBtn cursorPointer" @click="() => {searchParam.procureSn = '';searchParam.status = null;searchList()}">重置</div>
		     </div>
		     <div class="center_top_right" @click="() => showpublish=true">发布采购需求</div>
		 </div>
		 
		 <div class="order_title_info flex_row_start_center" style="margin-bottom: 0;">
		     <div class="info_item" style="width: 150px;">采购单号</div>
		     <div class="info_item" style="width: 200px;">产品名称</div>
		     <div class="info_item" style="width: 200px;">规格参数</div>
		     <div class="info_item" style="width: 100px;">采购数量</div>
		     <div class="info_item" style="width: 150px;">发布时间</div>
			 <div class="info_item">状态</div>
		     <div class="info_item">{{L['操作']}}</div>
		 </div>
		 <div class="newOrder_items"  v-for="(orderItem,index) in orderInfoList.data" :key="index">
			 <div class="newOrder_item" style="width: 150px;">{{ orderItem.procureSn }}</div>
			 <div class="newOrder_item cursorPointer setOverflow" style="width: 200px;padding: 0 12px;" :title="orderItem.productName">{{ orderItem.productName }}</div>
			 <div class="newOrder_item cursorPointer setOverflow" style="width: 200px;padding: 0 12px;" :title="orderItem.specValue">{{ orderItem.specValue }}</div>
			 <div class="newOrder_item" style="width: 100px;">{{ orderItem.procureNum }}</div>
			 <div class="newOrder_item" style="width: 150px;">{{ orderItem.createTime }}</div>
			 <div class="newOrder_item">{{['不通过', '通过', '审核中'][orderItem.status]}}</div>
			 <div class="newOrder_item cursorPointer" @click="go_orderIofo({type: 0, procureSn:orderItem.procureSn})">查看详情</div>
		 </div>
	<!-- 	 <el-pagination @current-change="handleCurrentChange" :currentPage="pageData.current"
		     :page-size="pageData.pageSize" layout="prev, pager, next, jumper" :total="pageData.total"
		     :hide-on-single-page="true" class="flex_row_end_center"></el-pagination> -->
		 <SldCommonEmpty v-if="orderInfoList.data.length==0" :tip="L['暂无订单～']" totalWidth="934px" />
	 </div>
	 <div class="pulishOrder" v-show="showpublish">
			<div class="formItems">
				<div class="formitem">
					<span>产品名称：</span>
					<input type="text" placeholder="请输入产品名称" v-model="pubParams.productName" maxlength="40" />
				</div>
				<div class="formitem">
					<span>规格参数：</span>
					<input type="text" placeholder="请输入规格参数" v-model="pubParams.specValue" maxlength="40"/>
				</div>
				<div class="formitem">
					<span>采购数量：</span>
					<input type="number" placeholder="请输入采购数量" v-model="pubParams.procureNum" />
				</div>
				<div class="formitem">
					<span>产品单位：</span>
					<input type="text" placeholder="请输入产品单位(例如：米、件、吨)" v-model="pubParams.procureUnit" maxlength="40" />
				</div>
				<div class="formitem">
					<span>联系人：</span>
					<input type="text" placeholder="请输入联系人名称" v-model="pubParams.contacts" maxlength="40" />
				</div>
				<div class="formitem">
					<span>联系电话：</span>
					<input type="text" placeholder="请输入联系电话" v-model="pubParams.telephone" />
				</div>
				<div class="pubBtn" @click="publishHandle">发布</div>
			</div>
	 </div>
  </div>
</template>
<script>
  import { ElMessage } from "element-plus";
  import { reactive, getCurrentInstance, onMounted, ref, watchEffect, watch } from 'vue'
  import collectStoreItem from './collectStoreItem'
  import { useRoute,useRouter } from 'vue-router'
  import { useStore } from 'vuex';
  import MemberTitle from '../../components/MemberTitle';
  import SldCommonEmpty from '../../components/SldCommonEmpty'
  export default {
    name: 'MemberCollect',
    components: {
      collectStoreItem,
      MemberTitle,
      SldCommonEmpty
    },
    setup() {
      const route = useRoute();
	  const router = useRouter();
      const store = useStore();
	  const { proxy } = getCurrentInstance();
	  const L = proxy.$getCurLanguage();
	  const showpublish = ref(false);
	  let clicked = false; // 是否点击

	  // 从首页发布采购信息过来
	  showpublish.value = route.query.showPub && Number(route.query.showPub) === 1;
	  
	  const orderInfoList = reactive({ data: [], pagination: {} });
	  const searchParam = reactive({ procureSn: '', status: null });
	  const pubParams = reactive({ });

	  const searchList = () => {
	  	proxy.$get("v3/business/front/orderInfo/procureList", searchParam).then(res => {
	  	  if (res.state == 200) {
	  			const data = res.data || {};
	  			orderInfoList.data = data.list || [];
	  			orderInfoList.pagination = data.pagination || {};
	  	  }
	  	});
      }
      const go_orderIofo = (params) => {
          router.push({ path: '/info/operateDetail/', query: params});
      }
	  const publishHandle = () => {
		  if(!clicked){
			  clicked = true;
			  	if(isNaN(pubParams.procureNum) || pubParams.procureNum == ''){
					ElMessage.error('请输入正确的采购数量');
			  		clicked = false;
			  		return ;
				}else if(pubParams.procureNum < 0){
					ElMessage.error('采购数量只能填正整数');
			  		clicked = false;
					return ;
				}
				if(proxy.$checkMobile(pubParams.telephone) !==true){
					ElMessage.error(proxy.$checkMobile(pubParams.telephone));
			  		clicked = false;
					return ;
				}
				proxy.$post("v3/business/front/orderInfo/supplyProcure", pubParams, "application/json").then(res => {
					if (res.state == 200) {
						ElMessage.success(res.msg);
						showpublish.value = false;
			  			clicked = false;
						// reset
						pubParams.productName = '';
						pubParams.specValue = '';
						pubParams.procureNum = '';
						pubParams.procureUnit = '';
						pubParams.contacts = '';
						pubParams.telephone = '';
						
						searchList();
					}else{
						ElMessage.error(res.msg);
			  			clicked = false;
					}
				});
		  }
	  };
	  onMounted(() => {
			searchList();
	  });
      return {
		  searchParam,
		  publishHandle,
		  pubParams,
		  searchList,
		  showpublish,
		  orderInfoList,
          L,
          go_orderIofo,
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import "@/style/member/orderInfo.scss";
  .setOverflow{
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
  }
  .cursorPointer{
  	cursor: pointer;
  }
  .newOrder_items{
	  width: 100%;
	  color: #585858;    
	  display: flex;
	  .newOrder_item{
		  width: 100px;  
		  height: 36px;
		  line-height: 36px;
          text-align: center;
	  } 
  }
</style>